import dynamic from "next/dynamic";
import cn from "clsx";
import { Content } from "@prismicio/client";
import { SingleAvisNormal } from "./SingleAvisNormal";
import { SingleAvisVideo } from "./SingleAvisVideo";
import { SingleAvisCitation } from "./SingleAvisCitation";
import style from "./SingleAvis.module.scss";

type PageProps = {
  items: Content.AvisDocument[];
  showSlider?: boolean;
  isHomePage?: boolean;
  sliderSettings?: any;
};

const SliderSlick = dynamic(
  () => import("@components/commun").then((mod) => mod.SliderSlick),
  {
    ssr: false,
    loading: () => null,
  },
);

export const SingleAvis = ({
  items,
  showSlider,
  sliderSettings,
  isHomePage = false,
}: PageProps) => {
  const avis = items.map((item, index) => {
    const isVideo = item.data.type === "video";
    const isCitation = item.data.type === "citation";

    if (showSlider === true && (isVideo || isCitation)) return null;

    return isVideo ? (
      <SingleAvisVideo key={index} item={item} />
    ) : isCitation ? (
      <SingleAvisCitation key={index} item={item} />
    ) : (
      <SingleAvisNormal key={index} item={item} isHomePage={isHomePage} />
    );
  });

  if (showSlider === true && sliderSettings !== undefined) {
    return (
      <div className={cn(style.items, style.slider, "slider-avis")}>
        <SliderSlick settings={sliderSettings}>{avis}</SliderSlick>
      </div>
    );
  }

  return <div className={style.items}>{avis}</div>;
};
