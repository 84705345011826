import Image from "next/image";
import { Trans, useTranslation } from "next-i18next";
import { TitlePage } from "@components/commun";
import { Content } from "@prismicio/client";
import { SingleAvis } from "@components/pages/avis/SingleAvis/SingleAvis";
import Picto from "@static/images/icons/yellow-heart.png";
import style from "./SliderReviews.module.scss";

type PageProps = {
  reviews: Content.AvisDocument[];
  isHomePage?: boolean;
};

export const SliderReviews = ({ reviews, isHomePage = false }: PageProps) => {
  const { t } = useTranslation();

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 100,
    speed: 19000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          slidesToShow: 2.5,

          centerMode: false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,

          centerMode: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          speed: 25000,
        },
      },
    ],
  };

  return (
    <section className={style.root}>
      <div className={style.head}>
        <div className="lk-wrap">
          <div className="lk-wrap-content">
            <div className={style.title}>
              <TitlePage>
                <span className={style.txt}>
                  {t("pages.everyoneHasProgram")}
                </span>
                <span className={style.motif}>
                  <Image src={Picto} alt="" />
                </span>
              </TitlePage>
            </div>
            <div className={style.subtitle}>
              <Trans
                t={t}
                i18nKey={"pages.dietbonCustomers"}
                components={{
                  br: <br />,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.content}>
        <div className="slider-reviews">
          <SingleAvis
            items={reviews}
            showSlider={true}
            sliderSettings={sliderSettings}
            isHomePage={isHomePage}
          />
          <div className="slider-reviews-rtl">
            <SingleAvis
              items={reviews}
              showSlider={true}
              sliderSettings={{ ...sliderSettings, initialSlide: 4 }}
              isHomePage={isHomePage}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
