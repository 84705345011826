import { FC } from "react";
import style from "./ModalVideo.module.scss";

export interface Props {
  url: string;
  close: () => void;
}

export const ModalVideo: FC<Props> = ({ url, close }) => {
  return (
    <div className={style.videoContenaire}>
      <div>
        <div>
          <button type="button" onClick={close} className={style.btnClose}>
            close
          </button>
          <iframe width="740" height="380" src={url} title="videoFrame">
            &amp;
          </iframe>
        </div>
      </div>
    </div>
  );
};
