import { useState } from "react";
import cn from "clsx";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { TitlePage } from "@components/commun";
import { Content } from "@prismicio/client";
import { components } from "../../../../slices";
import style from "./SliderProducts.module.scss";

type PageProps = {
  page: Content.BlockSliderDocument;
  bar?: Content.BarreProgrammeSlice;
};

export const SliderProducts = ({ page, bar }: PageProps) => {
  const [category, setCategory] = useState(
    page.data.slices[0]?.primary.category,
  );

  return (
    <>
      <section className={style.root}>
        <div className={style.head}>
          <div className="lk-wrap">
            <div className="lk-wrap-content">
              <div className={style.title}>
                <TitlePage>
                  <PrismicRichText field={page.data.title} />
                </TitlePage>
              </div>
              <div className={style.subtitle}>
                <PrismicRichText field={page.data.subtitle} />
              </div>
            </div>
          </div>
        </div>
        <div className={style.tabs}>
          {page.data.slices.map((slice) => {
            const numberOfItems =
              slice.primary.category === category && slice.primary.count;

            return (
              <div
                className={cn(style.tab, {
                  [style.active]: slice.primary.category === category,
                })}
              >
                <span
                  role="presentation"
                  onClick={() => setCategory(slice.primary.category)}
                >
                  {numberOfItems} {slice.primary.category}
                </span>
              </div>
            );
          })}
        </div>
        <div className={style.content}>
          <div className="slider-products">
            {page.data.slices.map((slice) =>
              slice.primary.category === category ? (
                <SliceZone slices={[slice]} components={components} />
              ) : null,
            )}
          </div>
        </div>
      </section>
      {bar && (
        <div className={style.bar}>
          <SliceZone slices={[bar]} components={components} />
        </div>
      )}
    </>
  );
};
