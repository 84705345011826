import { useTranslation } from "next-i18next";
import cn from "clsx";
import { PrismicRichText } from "@prismicio/react";
import { PrismicNextImage } from "@prismicio/next";
import { Content } from "@prismicio/client";
import { URL_PAGE_AVIS } from "@middleware/constants";
import { Link, Text } from "@components/ui";
import style from "./SingleAvis.module.scss";

type PageProps = {
  item: Content.AvisDocument;
  isHomePage?: boolean;
};

export const SingleAvisNormal = ({ item, isHomePage = false }: PageProps) => {
  const { t } = useTranslation();
  const isOptimum = item.data.program === "optimum";

  return (
    <div className={style.item} data-position={item.data.position}>
      <div className={cn(style.feature, { [style.showLink]: !isHomePage })}>
        <PrismicNextImage
          field={item.data.featureExcerpt}
          alt=""
          width="155"
          height="245"
        />
      </div>
      <div className={style.infos}>
        <Text variant="span" className={style.name}>
          {item.data.name}
        </Text>
        <Text variant="span" className={style.weight}>
          {item.data.weightLost}
        </Text>
        <Text
          variant="span"
          className={cn(style.program, {
            [style.optimum]: isOptimum,
          })}
        >
          {t("pages.programType", { type: item.data.program })}
        </Text>
        <div className={style.description}>
          <PrismicRichText field={item.data.shortDescription} />
        </div>
      </div>
      {!isHomePage && (
        <div className={cn("slider-action", style.action)}>
          <Link href={URL_PAGE_AVIS + "/" + item.uid}>
            {t("buttons.discoverStory")}
          </Link>
        </div>
      )}
    </div>
  );
};
