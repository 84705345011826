import Image from "next/image";
import cn from "clsx";
import { Content } from "@prismicio/client";
import style from "./SingleAvis.module.scss";

type PageProps = {
  item: Content.AvisDocument;
};

export const SingleAvisCitation = ({ item }: PageProps) => {
  return (
    <div
      className={cn(style.item, style.citation)}
      data-position={item.data.position}
    >
      <div className={style.feature}>
        <Image
          src={item.data.featureExcerpt.url as string}
          alt=""
          width="480"
          height="326"
        />
      </div>
    </div>
  );
};
